import React from "react";
import { Component } from "react";
import { Row, Col, Container } from "react-bootstrap"
import ButtonLink from "./buttonLink";
import ImageDiv from "./GatsbyImage/ImageDiv";
import Fade from "./transition/Fade";

class Buttons extends Component {
    text(item) {
        return (
            <div className="text-center border border-primary py-2 my-2">
                {!item.link && item.title ? '' :
                        <ButtonLink href={item.link} label={item.title} className="bg-transparent text-white h3 text-center" />
                }
            </div>
        )
    }
    img(item) {
        const link = item.image + '';
        if (link.includes('youtu.be') || link.includes('youtube')) {
            return (
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src={item.image} title={item.altImage} allowFullScreen></iframe>
                </div>
            )
        }
        else {
            return (
                <Fade>
                    <ImageDiv src={"/" + item.image} alt={item.altImage} className="img-fluid" />
                </Fade>)
        }
    }
    textImg(item) {
        if (item.image === null) {
            return (
                <Col md={7} sm={7} className="align-self-center">
                    {this.text(item)}
                </Col>
            )
        } else {
            return (
                <>
                    <Col md={7} sm={7} className="align-self-center">
                        {this.text(item)}
                    </Col>
                    <Col md={5} sm={5} className="align-self-center">
                        {this.img(item)}
                    </Col>
                </>
            )
        }
    }
    imgText(item) {

        if (item.image === null) {
            return (
                <Col md={7} sm={7} className="align-self-center">
                    {this.text(item)}
                </Col>
            )
        } else {
            return (
                <>
                    <Col md={7} sm={7} className="align-self-center d-block d-md-none d-sm-none d-lg-none">
                        {this.text(item)}
                    </Col>
                    <Col md={5} sm={5} className="align-self-center">{this.img(item)}</Col>

                    <Col md={7} sm={7} className="align-self-center d-none d-md-block d-sm-block d-lg-block">
                        {this.text(item)}
                    </Col>
                </>
            )
        }
    }

    render() {
        const { sections } = this.props
        if (sections) {
            return (
                <Container className="mt-1 pt-0">
                    {sections.map((item, index) => {
                        return (
                            <Row key={'ID' + item.id} id={item.id} className='mt-1 pt-2 justify-content-center'>
                                {index % 2 === 1 ?
                                    this.textImg(item) : this.imgText(item)
                                }
                            </Row>
                        )
                    })}
                </Container>
            )
        }
        else {
            return (
                <Container className="mt-2 pt-1"></Container>
            )
        }
    }
}
export default Buttons