import React from "react";
import { Component } from "react";
import { Row, Col, Container, Card } from "react-bootstrap"
import AutoIcon from "./AutoIcon";
import ButtonLink from "./buttonLink";
import Fade from "./transition/Fade";
import ReactMarkdown from 'react-markdown'
import ImageDiv from "./GatsbyImage/ImageDiv";

class Blog extends Component {

    render() {
        const { sections } = this.props
        if (sections) {
            return (
                <Container className="py-3">
                    <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        {sections.map((item, index) => {
                            let altImage = item.labelImage || item.title;
                            return (
                                <Col key={'ID' + item.id} sm={4} className="d-flex align-items-stretch">
                                    <Fade>
                                        <Card className="card shadow-sm my-1" >
                                            {/* <Card.Img src={"/" + item.image} variant="top" aria-label={altImage} alt={altImage} ></Card.Img> */}
                                            <ImageDiv className="card-img-top" src={item.image} alt={altImage}></ImageDiv>
                                            <Card.Body>
                                                <Card.Title>
                                                    <AutoIcon text={item.title} /> {item.title}
                                                </Card.Title>
                                                <hr className="text-primary" />
                                                    <ReactMarkdown className="card-text">{item.body}</ReactMarkdown>
                                                {item.link || item.date ?
                                                    <Card.Text className="text-center">
                                                        {item.link ? <ButtonLink href={item.link} label={item.linkLabel} title={item.title}></ButtonLink> : ''}
                                                        {item.date ? <small className="text-muted">{item.date}</small> : ''}
                                                    </Card.Text> : ''
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Fade>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            )
        }
        else {
            return (
                <Container className="mt-2 pt-1"></Container>
            )
        }
    }
}
export default Blog