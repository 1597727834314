import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import Layout from "../components/layout";
import Meta from "../components/Meta";
import Section from "../components/section";
import Timeline from "../components/Timeline";
import Slider from "../components/Slider";
import { graphql } from "gatsby";
import Blog from "../components/Blog";
import Buttons from "../components/Buttons";
import ReactMarkdown from "react-markdown";

export default function compositeTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter } = markdownRemark;
  const { section, slider } = frontmatter;

  const { allMarkdownRemark } = data; // data.markdownRemark holds our post data

  return (
    <Layout>
      <Meta title={frontmatter.title} metaTitle={frontmatter.metaTitle} metaDescription={frontmatter.metaDescription} />
      <Container className="text-center my-2 py-2">
        <Row>
          <Col fluid><h1>{frontmatter.title}</h1></Col>
        </Row>
      </Container>
      <Slider slider={slider} />
      <Container className="text-center mt-1">
        <Row>
          <Col fluid><h2>{frontmatter.bannerTitle}</h2></Col>
        </Row>
      </Container>
      {
        frontmatter.aggregators ? frontmatter.aggregators.map((aggr, i) => {
          return (
            <Section key={i} sections={ 
                allMarkdownRemark.edges
                  .filter((itm) => {
                    return itm.node.frontmatter.static === aggr.key 
                  })
                  .map( (it) => { return it.node.frontmatter })
                  .sort((a, b) => a.date > b.date ? -1 : 1)
                  .map( (item, index) => {
                    const el = item;
                    const img = el.slider ? el.slider[0] : null;
                    var obj = {
                      id: "aggr-" + aggr.key + "-" + index,
                      title: el.metaTitle,
                      body: el.metaDescription,
                      link: "/" + el.static + el.path + "/",
                      linkLabel: aggr.linkLabel,
                      image: img ? img.image : '',
                      altImage: img ? img.altImage : '',
                    }
                    return obj;
                  })
            }></Section>
          )
        }) : ''
      }
      {
        //<Section sections={allMarkdownRemark.edges.map((item, index) => {
        //   const el = item.node.frontmatter;
        //   const img = el.slider ? el.slider[0] : null;
        //   var obj = {
        //     id: "aggr-" + index,
        //     title: el.metaTitle,
        //     body: el.metaDescription,
        //     link: "/" + el.static + el.path + "/",
        //     linkLabel: "Scatta alle info",
        //     image: img ? img.image : '',
        //     altImage: img ? img.altImage : '',
        //   }
        //   return obj;
        // })
        // }></Section>
      }
        { !section ? '' :
          section.map((item, index) => {
            let Render = Section;
            if (item.layout === 'timeline') {
              Render = Timeline
            } else if (item.layout === 'blog') {
              Render = Blog;
            } else if (item.layout === 'slider') {
              Render = Slider;
            } if (item.layout === 'buttons') {
              Render = Buttons;
            }
            return (
              <Container className="mt-3" key={item.layout + index}>
                <Row><Col><hr className="my-3 solid text-primary" /></Col></Row>
                {item.title ? <Row><Col><h2 className="text-center h2">{item.title}</h2></Col></Row> : ''}
                {item.title ? <Row><Col><hr className="my-3 solid text-primary" /></Col></Row> : ''}
                {item.body ? <Row><Col><ReactMarkdown>{item.body}</ReactMarkdown></Col></Row> : ""}
                <Render sections={item.content} vertical={false}></Render>
              </Container>
            )
          })
        }
        {
          frontmatter.date ? <Container><Row>
            <Col className="text-right"><time datetime={frontmatter.date}>Aggiornato il {frontmatter.date}</time></Col></Row>
          </Container> : ''
        }
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!, $staticFilter: [String]!) {
    markdownRemark(id: {eq: $id}) {
      frontmatter {
        date(formatString: "DD/MM/YYYY")
        path
        bannerTitle
        title
        content
        altImage
        image
        metaTitle
        metaDescription
        static
        aggregators {
          key
          linkLabel
          title
          body
          order
        }
        section {
          id
          title
          layout
          link
          body
          linkLabel
          content {
            id
            title
            body
            altImage
            image
            link
            linkLabel
          }
        }
        slider {
          id
          image
          title
          altImage
          link
        }
      }
    }
    allMarkdownRemark(filter: {frontmatter: {static: {in: $staticFilter}}}) {
      edges {
        node {
          frontmatter {
            date
            path
            static
            metaTitle
            metaDescription
            bannerTitle
            slider {
              altImage
              image
              title
            }
          }
        }
      }
    }
}
`