import React from "react";
import { Component } from "react";
import { Col, Container } from "react-bootstrap"
import ButtonLink from "./buttonLink";
import ImageDiv from "./GatsbyImage/ImageDiv";
import Fade from "./transition/Fade";
import ReactMarkdown from 'react-markdown'

class Section extends Component {
    text(item) {
        return (
            <>
                <h2 className="py-1 d-none d-sm-none d-md-block">{item.title}</h2>
                <hr className="solid text-primary d-none d-sm-none d-md-block" />
                <div className="d-none d-sm-none d-md-block">
                    <ReactMarkdown>{item.body}</ReactMarkdown>
                </div>
                {!item.link && item.linkLabel ? '' :
                    <p className="text-center d-none d-sm-none d-md-block">
                        <ButtonLink href={item.link} label={item.linkLabel} title={item.title} />
                    </p>
                }
            </>
        )
    }
    img(item) {
        const link = item.image + '';
        if (link.includes('youtu.be') || link.includes('youtube') || 
            (link.includes('facebook') && link.includes('plugins')) 
        ) {
            return (
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe className="embed-responsive-item" src={item.image} title={item.altImage} allowFullScreen></iframe>
                </div>
            )
        }
        else {
            return (
                <Fade>
                    <ImageDiv src={"/" + item.image} alt={item.altImage} className="img-fluid" />
                </Fade>)
        }
    }
    textImg(item) {
        if (item.image === null ) {
            return (
                <Col md={7} className="align-self-center">
                    {this.text(item)}
                </Col>
            )
        } else {
            return (
                <>
                <Col md={7} className="align-self-center">
                    {this.text(item)}
                </Col>
                <Col md={5} className="align-self-center">
                    {this.img(item)}
                </Col>
            </>
            )
        }
    }
    imgText(item) {
        
        if (item.image === null ) {
            return (
                <Col md={7} className="align-self-center">
                    {this.text(item)}
                </Col>
            )
        } else {
            return (
                <>
                    <Col md={5} className="align-self-center">{this.img(item)}</Col>
                    <Col md={7} className="align-self-center">
                        {this.text(item)}
                    </Col>
                </>
            )
        }
    }

    render() {
        const { sections } = this.props
        if (sections) {
            return (
                <Container className="mt-1 pt-0">
                    {sections.map((item, index) => {
                        return (
                            <div key={'ID' + item.id} id={item.id} className='mt-1 pt-2 justify-content-center row'>
                                <h2 className="py-1 d-sm-block d-md-none">{item.title}</h2>
                                <hr className="solid text-primary d-sm-block d-md-none" />
                                {index % 2 === 1 ?
                                    this.textImg(item) : this.imgText(item)
                                }
                                <div className="d-sm-block d-md-none">
                                    <ReactMarkdown>{item.body}</ReactMarkdown>
                                </div>
                                {!item.link && item.linkLabel ? '' :
                                    <p className="text-center d-sm-block d-md-none">
                                        <ButtonLink href={item.link} label={item.linkLabel} title={item.title} />
                                    </p>
                                }
                            </div>
                        )
                    })}
                </Container>
            )
        }
        else {
            return (
                <Container className="mt-2 pt-1"></Container>
            )
        }
    }
}
export default Section